import {ModelBase} from './abstract/model-base';

export class Campaign extends ModelBase {
    public id: string                  = null;
    public name: string                = null;
    public totalOrders: number         = null;
    public totalOrderItems: number     = null;
    public startAt: string             = null;
    public convertedOrders: number     = null;
    public convertedOrderItems: number = null;

    constructor() {
        super();
        super.setRoutes({
                            post:    'v1.0/campaign',
                            getMany: 'v1.0/campaign',
                            get:     'v1.0/campaign/:id',
                            patch:   'v1.0/campaign/:id',
                            delete:  'v1.0/campaign/:id'
                        });
    }

    mapModel(data: any): Campaign {
        const model               = new Campaign();
        model.id                  = data.id;
        model.name                = data.name;
        model.totalOrders         = data.totalOrders;
        model.totalOrderItems     = data.totalOrderItems;
        model.startAt             = data.startAt;
        model.convertedOrders     = data.convertedOrders;
        model.convertedOrderItems = data.convertedOrderItems;

        return model;
    }
}

export function mapCampaign(item: any): Campaign {
    return new Campaign().mapModel(item);
}
